import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { Helmet } from 'react-helmet';
import theme from '../styles/theme';
import Foundation from '../components/Foundation';
import Footer from '../components/Footer';
import Logo from '../images/logo.png';

const FoundationPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Bhagat & Kumar - Chartered Accountants | CSR</title>
      <meta name="title" content="Bhagat and Kumar - Chartered Accountants | CSR" />
      <meta
        name="description"
        content="Bhagat & Kumar Chartered Accountants."
      />

      <meta property="og:title" content="Bhagat & Kumar CSR" />
      <meta property="og:url" content={`${process.env.SITE_URL}/csr`} />
      <meta
        property="og:description"
        content="“BHAGAT & KUMAR FOUNDATION, a trust formed by both the partners in 2020 , with a sole and concrete motto of “Start when you can, rather than when you have” , for doing their bit for the society."
      />
      <meta property="og:image" content={Logo} />

      <meta property="twitter:card" content="about" />
      <meta property="twitter:image" content={Logo} />
      <meta property="twitter:site" content="@bhagatandkumar" />
      <meta property="twitter:title" content="Bhagat & Kumar CSR" />
      <meta
        property="twitter:description"
        content="“BHAGAT & KUMAR FOUNDATION, a trust formed by both the partners in 2020 , with a sole and concrete motto of “Start when you can, rather than when you have” , for doing their bit for the society."
      />
    </Helmet>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Foundation />
      <Footer />
    </MuiThemeProvider>
  </>
);
export default FoundationPage;
