import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Header from '../Header';
import Typography from '../Typography';

const useStyles = makeStyles({
  header: {
    marginTop: '70px',
  },
  info: {
    padding: '50px',
  },
});
export default function Foundation() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Paper elevation={0}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                marked="center"
                style={{ paddingTop: '20px' }}
              >
                Our CSR
              </Typography>
            </Grid>
          </Grid>
        </section>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.info}
          style={{ textAlign: 'justify' }}
        >
          <b>BHAGAT & KUMAR FOUNDATION</b>
          , a trust formed by both the partners
          in 2020 , with a sole and concrete motto of
          <b> “Start when you can, rather than when you have”</b>
          , for doing their bit for the society. Notwithstanding the fact that,
          both partners being brought up in humble background, realized the
          essence of a successful life- the contribution to the society needs to
          be done from the very moment you start earning, rather than waiting
          till the time wealth is accumulated. Keeping this principle in mind,
          the firm, every year donates, 5% of its Profits to the foundation for
          doing the charity work.
          <br />
          <br />
          Both the Partners believe in doing whatever they can do, to the best
          of their capacity to improve the lives of those, who have not been
          fortunate enough to meet their ends. The appeal of the foundation is
          to start giving back to the society from the moment, even when we have
          very little rather than waiting for the wealth to get accumulated &
          then contribute. Hence the foundation logo is
          <b> “Start when you can, rather than when you have”</b>
          <br />
          <br />
          B&K Foundation, was an inspiration taken from the likes of Mr.Ratan
          Tata, Mr.Azim Premji & Mr.Bill Gates. A story, once narrated by
          Mr.Gates, left a profound influence in our hearts and hence, we would
          like to share the very basic thought of establishing the foundation.
          <br />
          Someone asked the richest man of the world, Mr.Gates- “ Is there anyone
          richer than you in the world?”
          He said, yes, only one. Many years ago, I had been dismissal and I had
          gone to New York airport. I read titles of newspapers there. I liked
          one of them and I want to buy it. But I didn&apos;t have change
          (coin). I abandoned, suddenly, a black boy called me and told, “This
          newspaper for you.” I said, but I don’t have change. He said, “No
          problem, I give you free”.
          <br />
          After 3 months, I went there. Coincidentally, that story happened
          again and that same boy gave another free newspaper again. I said, I
          can’t accept. But he said, “I give you from my profit.”
          <br />
          After 19 years, I had been rich and I decided to find that boy. I
          found him after one and half months searching. I asked him, do you
          know me? He said, “Yes, you’re famous Bill Gates.”
          <br />
          I said, you gave me free newspaper in 2 times many years ago. Now, I
          want to compensate it. I am going to give you everything that you
          want. Black young man replied, “You can’t compensate it!” I said, why?
          He said, &quot;Because I gave you when I was poor. You want to give me
          when you are rich. So, how do you compensate?&quot;
          <br />
          Mr.Gates said, I think that black young man is richer than me.
          &quot;You don&quot;t have to be rich or wait to be rich to give&quot;.
          <br />
          <br />
          It is very important to have a rich heart than to have a lot of money.
          Hence, at B&K, we strive to do the best to our capacity, through the following ways:
          <br />
          <br />
          <b>1. Contribution towards society: </b>
          <br />
          <span>
            The foundation is involved in providing books, basic infrastructure
            & meals in the Schools, actively donating to the Central Govt Armed
            forces Relief fund for the welfare of the families of martyred Armed
            forces personnel. Trustees personally devote their time in teaching
            the kids of poor families, providing medical & food facilities to
            underprivileged, irrespective of their religion, caste or creed.
            Since, we take our resources from the society, we understand our
            responsibility towards Mother Nature, hence we also ensure our
            generous contribution towards Mother Earth, by ‘Going Green’. If you
            plant a tree, you bring a life, hence B&K as a family, ensure clean
            and green environment around us. We also firmly believe that, we as
            a young and energetic Indians, must stand up together and join our
            leaders in nation building, even though our contribution on a macro
            level might appear marginal. We hereby, take an opportunity to urge
            people, to join us in our efforts of nation building, and be a part
            of a wonderful journey, by improving the lives of people around us.
            <br />
            <br />
            <LabelImportantIcon />
            <b> Tribute to Armed Forces:</b>
            <br />
            Foundation paid respect to armed forces personnel during the year
            2019, by celebrating their bravery, post pulwama attacks. Expressing
            gratitude to the services of armed forces in India, B&K filed their
            income tax returns & provided consultancy services, at nominal fee
            for the FY 2018-19.
            <br />
            <br />
            <LabelImportantIcon />
            <b> Tribute to Medical Professionals:</b>
            <br />
            During the year 2020, when country was facing the pandemic situation
            due to COVID-19, the doctors and the medical & emergencies
            personnel, were fighting with all their might and valour on the fore
            front, against the invisible enemy. During this time, B&K not only
            donated generously to PM care fund, but also decided to pay respect
            to this warriors by filing their income tax returns & to provide
            consultancy services, free of cost for the entire year.
          </span>
          <br />
          <br />
          <b>2. Contribution within the firm: </b>
          <br />
          <span>
            We at B&K have undertaken simple and common activities as part of
            our CSR which includes business ethics in our client dealings,
            diversity and equal opportunities for staff. Other key CSR issues
            include stakeholder engagement, maintaining labour standards and
            working conditions, employee and community relations, social equity,
            gender balance, human rights, good governance and anti-corruption
            measures. Providing learning & development opportunity to staff and
            students.The farsighted leaders at B&K have led the firm with
            integrity and good reputation that has been established through
            decades of doing business ethically. We promote equality by making
            it possible for parents to combine work and family, and giving women
            and men equal opportunities to rise to leadership positions. We are
            proud of our progress and of our team who carry out our corporate
            social responsibility commitments routinely in communities in and
            around Karnataka.
          </span>
        </Typography>
      </Paper>
    </>
  );
}
